<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-tabs>
      <a-tab-pane key='1' tab='商品信息'>
        <a-card :body-style="{padding: '24px 32px'}" :bordered='false'>
          <a-form-model ref='form' :model='form' :rules='rules' :labelCol='labelCol' :wrapperCol='wrapperCol'>
            <a-form-model-item label='商品名称' prop='title'>
              <a-input v-model='form.title' />
            </a-form-model-item>
            <a-form-model-item label='关联仓库商品' prop='depositoryProduction'>
              <a-select show-search v-model='form.depositoryProduction'
                        :default-active-first-option='false'
                        :filter-option='false'
                        :allowClear="true"
                        placeholder="请选择"
                        @search='handleSearchProd'
              >
                <a-select-option v-for='item in prodList' :key='item.id'>
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label='兑换积分' prop='price'>
              <a-input-number style='width: 100%' v-model='form.price' :min='1' :precision='0' />
            </a-form-model-item>

            <a-form-model-item label='商品数量' prop='quantity'>
              <a-input-number style='width: 100%' v-model='form.quantity' :min='1' />
            </a-form-model-item>

            <a-form-model-item label='产品封面' prop='cover'>
              <a-upload
                list-type='picture-card'
                class='avatar-uploader'
                :show-upload-list='false'
                :customRequest='handleCoverUpload'>
                <img v-if='form.cover' :src='form.cover' alt='cover' width='375px' />
                <div v-else>
                  <a-icon :type="coverLoading ? 'loading' : 'plus'" />
                  <div class='ant-upload-text'>上传</div>
                </div>
              </a-upload>
            </a-form-model-item>
            <a-form-model-item label='上架状态' prop='status'>
              <a-radio-group v-model='form.status'>
<!--                <a-radio value='draft'>草稿</a-radio>-->
                <a-radio value='online'>上架</a-radio>
                <a-radio value='offline'>下架</a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label='热门兑换' prop='popular'>
              <a-radio-group v-model='form.popular'>
                <a-radio :value='0'>否</a-radio>
                <a-radio :value='1'>是</a-radio>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item label='是否推荐' prop='recommend'>
              <a-radio-group v-model='form.recommend'>
                <a-radio :value='0'>否</a-radio>
                <a-radio :value='1'>是</a-radio>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item label='广告图推荐' prop='advertCover'>
              <a-upload
                list-type='picture-card'
                class='avatar-uploader'
                :show-upload-list='false'
                :customRequest='handleAdvertCoverUpload'>
                <img v-if='form.advertCover' :src='form.advertCover' alt='advertCover' width='375px' />
                <div v-else>
                  <a-icon :type="advertCoverLoading ? 'loading' : 'plus'" />
                  <div class='ant-upload-text'>上传</div>
                </div>
              </a-upload>
            </a-form-model-item>

            <a-form-model-item label='产品摘要' prop='precis'>
              <a-textarea :auto-size='true' v-model='form.precis' />
            </a-form-model-item>
            <a-form-model-item label='产品画册' prop='gallery' v-if="gallery">
              <a-upload list-type='picture' :customRequest='handleGalleryUpload' :remove="handleGalleryRemove" :default-file-list='gallery'>
                <a-button>
                  <a-icon type='upload' />
                  上传图片
                </a-button>
              </a-upload>
            </a-form-model-item>
            <a-form-model-item label='产品详情' prop='contentRichText'>
              <quill-editor class='editor' prefix='production/html/' :value='form.contentRichText'
                            @change='handleContentRichTextChange'>
              </quill-editor>
            </a-form-model-item>

          </a-form-model>
          <a-form-model-item :wrapper-col=' { lg: { span: 15 ,offset: 7}, sm: { span: 17 ,offset: 7} }'>
            <a-button @click='handleReset'>
              重置
            </a-button>
            <a-divider type='vertical' />
            <a-button @click='handleCancel'>
              取消
            </a-button>
            <a-divider type='vertical' />
            <a-button type='primary' @click='handleSubmit'>
              提交
            </a-button>
          </a-form-model-item>
        </a-card>
      </a-tab-pane>
<!--      <a-tab-pane key='2' tab='商品评价' v-if='$route.query.id'>-->
<!--        <s-table ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1000}' :data='loadData'>-->
<!--          <span slot='content' slot-scope='text'>-->
<!--            <template>-->
<!--               <Ellipsis :length='50' tooltip>{{ text }}</Ellipsis>-->
<!--            </template>-->
<!--          </span>-->
<!--          <span slot='gallery' slot-scope='text'>-->
<!--            <template>-->
<!--               <a-carousel v-if='text' effect='fade' autoplay>-->
<!--                 <img v-for='gallery in text ' :src='gallery' :key='gallery' height='100'>-->
<!--               </a-carousel>-->
<!--            </template>-->
<!--          </span>-->
<!--          <span slot='status' slot-scope='text'>-->
<!--            <template>-->
<!--              <a-badge v-if='text=="init"' dot status='default' :text='"待审核"' />-->
<!--              <a-badge v-if='text=="audit"' dot status='processing' :text='"审核通过"' />-->
<!--              <a-badge v-if='text=="reject"' dot status='warning' :text='"审核拒绝"' />-->
<!--            </template>-->
<!--          </span>-->
<!--          <span slot='action' slot-scope='text,record'>-->
<!--            <template>-->
<!--              <a-space>-->
<!--                  <a v-if='record.status=="init"' @click='handleUpstateEvaluation(record,"audit")'>审核通过</a>-->
<!--                  <a v-if='record.status=="init"' @click='handleUpstateEvaluation(record,"reject")'>审核拒绝</a>-->
<!--              </a-space>-->
<!--            </template>-->
<!--          </span>-->
<!--        </s-table>-->
<!--      </a-tab-pane>-->
    </a-tabs>
  </page-header-wrapper>
</template>

<script>
import fs from 'string-to-file-stream'
import { STable, Ellipsis, QuillEditor } from '@/components'
import client from '@/config/oss.config.js'
import {
  getProduction,
  putProduction,
  queryProductionCategories,
  queryProductionEvaluations,
  upstateProductionEvaluation
} from '@/api/production'
import {
  queryCampusSubjects,
  getCampusSubject
} from '@/api/content'
import { queryInsideProd } from '@/api/inside'
import TagSelectOption from '@/components/TagSelect/TagSelectOption'
import { combine } from '@/config/quill.config'


const columns = [
  {
    width: 120,
    fixed: 'left',
    title: '#',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 120,
    title: '评价内容',
    dataIndex: 'content',
    scopedSlots: { customRender: 'content' }
  },
  {
    width: 120,
    title: '产品晒图',
    dataIndex: 'gallery',
    scopedSlots: { customRender: 'gallery' }
  },
  {
    width: 120,
    title: '审核状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    width: 120,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'ProductionEdit',
  data() {
    return {
      subjects: [],
      columns,
      gallery: "",
      categories: [],
      labelCol: { lg: { span: 7 }, sm: { span: 7 } },
      wrapperCol: { lg: { span: 15 }, sm: { span: 17 } },
      uuid: this.$uuid.v4(),
      cover: '',
      coverLoading: false,
      advertCoverLoading: false,
      parameter: {},
      prodList: [],
      form: {
        id: '',
        cover: '',
        advertCover:'',
        title: '',
        minQuantity: 1,
        status: 'offline',
        popular: 0,
        recommend:0,
        category: '',
        gallery: [],
        videoRichText: '',
        videoRichTextUrl: '',
        caseRichText: '',
        caseRichTextUrl: '',
        contentRichText: '',
        contentRichTextUrl: '',
        campusSubjectId: '',
        price: 1,
        originalPrice: 1,
        // serviceStorePrice: 1,
        // serviceCenterPrice: 1,
        // serviceStoreQuantity: 1,
        // serviceCenterQuantity: 1
      },
      rules: {
        title: [
          { required: true, message: '请填写产品标题', trigger: 'change' },
          { min: 6, max: 32, message: '产品标题字符数限定6～32个', trigger: 'change' }
        ],
        category: [
          { required: true, message: '请选择产品类型', trigger: 'change' }
        ],
        quantity: [
          { required: true, message: '请填写商品数量', trigger: 'change' },
          { type: 'integer', message: '商品数量填写整数', trigger: 'change' }
        ],
        price: [
          { required: true, message: '请填写积分', trigger: 'change' },
          { type: 'integer', message: '普通会员购买价格填写整数', trigger: 'change' }
        ],
        cover: [{ required: true, message: '请上传产品封面', trigger: 'change' }],
        advertCover: [{ required: true, message: '请上传广告推荐图', trigger: 'change' }],
        status: [
          { required: true, message: '请选择上架状态', trigger: 'change' }
        ],
        popular: [
          { required: true, message: '请选择最受欢迎', trigger: 'change' }
        ],
        recommend: [
          { required: true, message: '请选择是否推荐', trigger: 'change' }
        ],
        precis: [
          { required: true, message: '请填写产品摘要', trigger: 'change' },
          { min: 6, max: 64, message: '产品摘要字符数限定6～32个', trigger: 'change' }
        ],
        contentRichText: [{ required: true, message: '请编辑活动详情页面', trigger: 'change' }],
        contentRichTextUrl: [{ required: true, message: '编辑活动详情页面后自动生成', trigger: 'change' }],
        campusSubjectId: [{ required: true, message: '请选择所属栏目', trigger: 'change' }],
      },
      queryParam: { production: this.$route.query.id },
      loadData: parameter => {
        return queryProductionEvaluations(Object.assign(parameter, this.queryParam))
          .then(datum => {
            return datum
          })
      }
    }
  },
  components: { TagSelectOption, QuillEditor, STable, Ellipsis },
  created() {
    this.id = this.$route.query.id
    this.handleInitial(this.id )
    this.$form.createForm(this)
  },
  activated() {
    this.id = this.$route.query.id
    this.handleInitial(this.id )
    this.$form.createForm(this)

  },
  methods: {
    handleSearch1(keywords) {
      queryCampusSubjects({ current: 1, size: 1000, prototype: 'case', status: 'online', level: 0 }).then(result => {
        let subjects = result.records
        if (subjects.findIndex(item => item.id == this.form.campusSubjectId) < 0) {
          this.form.campusSubjectId = "";
        }
        this.subjects = subjects
      })
    },
    handleChange1(value) {
      this.form.campusSubjectId = value
    },
    handleSearchProd(keywords) {
      queryInsideProd({ keywords: keywords, pageMode: 0  }).then(result => {
        this.prodList = result.records
      })
    },
    async handleGalleryUpload(event) {
      let result = await client.put('production/' + event.file.uid, event.file)
      event.file.status = "done";
      this.gallery.push({
        uid: event.file.uid,
        name: event.file.name,
        status: event.file.status,
        url: result.url,
        thumbUrl: result.url,
      })
    },
    handleGalleryRemove(event) {
      let index = this.gallery.findIndex(item => item.uid == event.uid);
      if (index >= 0) {
        this.gallery.splice(index, 1);
      }
    },
    async handleCoverUpload(event) {
      this.coverLoading = true;
      let result = await client.put('production/' + event.file.uid, event.file)
      this.coverLoading = false;
      this.form.cover = result.url
    },
    async handleAdvertCoverUpload(event) {
      this.advertCoverLoading = true;
      let result = await client.put('production/' + event.file.uid, event.file)
      this.advertCoverLoading = false;
      this.form.advertCover = result.url
    },
    async handleContentRichTextChange(html) {
      this.form.contentRichText = html
    },
    async handleCaseRichTextChange(html) {
      this.form.caseRichText = html
    },
    async handleVideoRichTextChange(html) {
      this.form.videoRichText = html
    },
    handleUpstateEvaluation(record, status) {
      upstateProductionEvaluation({ id: record.id, status: status }).then(result => {
        this.$refs.table.refresh(true)
      })
    },
    handleSearch(keywords) {
      queryProductionCategories({ keywords: keywords, current: 1, size: 1000, status: 'online' }).then(result => {
        let categories = result.records
        if (categories.findIndex(item => item.id == this.form.category) < 0) {
          this.form.category = "";
        }
        this.categories = categories
      })
    },
    handleChange(value) {
      this.form.category = value
      return true
    },
    handleReset(e) {
      this.$refs.form.resetFields()
    },
    handleCancel(e) {
      this.$router.back()
    },
    handleInitial(id) {
      if (id) {
        getProduction(this.$route.query).then(result => {
          getCampusSubject({ id: result.campusSubjectId }).then(subject => {
            this.subjects = Array.of(subject)
          })
          this.form = Object.assign({}, this.form, result)
          const _gallery = []
          if (this.form.gallery) {
            let gallery = typeof this.form.gallery == 'object' ? this.form.gallery : JSON.parse(this.form.gallery);
              gallery.forEach(item => {
                _gallery.push({
                  uid: item,
                  name: item,
                  status: 'done',
                  url: item,
                  thumbUrl: item,
              })
            });
          }
          this.gallery = _gallery
        })
      } else {
        this.gallery = []
      }
      this.handleSearch()
      this.handleSearchProd()
    },
    async handleSubmit(e) {
      e.preventDefault()

      if (this.form.contentRichText) {
        const path = 'production/content/' + this.uuid + '.html'
        const result = await client.putStream(path, fs(combine(this.form.contentRichText), { path: path }))
        this.form.contentRichTextUrl = result.url
      }

      if (this.form.videoRichText) {
        const path = 'production/video/' + this.uuid + '.html'
        const result = await client.putStream(path, fs(combine(this.form.videoRichText), { path: path }))
        this.form.videoRichTextUrl = result.url
      }

      if (this.form.caseRichText) {
        const path = 'production/case/' + this.uuid + '.html'
        const result = await client.putStream(path, fs(combine(this.form.caseRichText), { path: path }))
        this.form.caseRichTextUrl = result.url
      }
      let gallery = [];
      for (const item of this.gallery) {
        gallery.push(item.url);
      }
      this.form.gallery = JSON.stringify(gallery);
      // debugger;
      this.$refs.form.validate((result) => {
        if (result) {
          putProduction(this.form).then(res => {
            this.$router.back()
          })
        }
      })
    }

  }
}
</script>
